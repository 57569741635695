import React from "react";
import "./landing-carousel.scss";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import Image1 from "../../data/images/Frame 1.jpg";
import Image2 from "../../data/images/Frame 2.jpg";
import Image3 from "../../data/images/Frame 3.jpg";

const LandingCarousel = () => {
  return (
    <div className="carousel-container">
      <MDBCarousel showIndicators fade>
        <MDBCarouselItem itemId={1} className="carousel-component">
          <div className="carousel-img">
            <img src={Image1} className="d-block w-100" alt="..." />
          </div>
          <MDBCarouselCaption>
            <div className="carousel-text">
              <p className="landing-p1">AkhurTath Zenith Solutions</p>
              <h5 className="landing-h5">AN AVIATION COMPANY</h5>
              <p className="landing-p2">
                MAKE DREAMS INTO REALITY WITH OUR PROJECT MANAGEMENT TEAM
              </p>
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId={2} className="carousel-component">
          <div className="carousel-img">
            <img src={Image2} className="d-block w-100" alt="..." />
          </div>
          <MDBCarouselCaption>
            <div className="carousel-text">
              <p className="landing-p1">Akhurath Zenith Solutions</p>
              <h5 className="landing-h5">A PROJECT MANAGEMENT COMPANY</h5>
              <p className="landing-p2">
                MAKE DREAMS INTO REALITY WITH OUR PROJECT MANAGEMENT TEAM
              </p>
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId={3} className="carousel-component">
          <div className="carousel-img">
            <img src={Image3} className="d-block w-100" alt="..." />
          </div>
          <MDBCarouselCaption>
            <div className="carousel-text">
              <p className="landing-p1">Akhurath Zenith Solutions</p>
              <h5 className="landing-h5">A PROJECT MANAGEMENT COMPANY</h5>
              <p className="landing-p2">
                MAKE DREAMS INTO REALITY WITH OUR PROJECT MANAGEMENT TEAM
              </p>
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>

        {/* <MDBCarouselItem itemId={2}>
          <img
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg"
            className="d-block w-100"
            alt="..."
          />
          <MDBCarouselCaption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </MDBCarouselCaption>
        </MDBCarouselItem> */}
      </MDBCarousel>
    </div>
  );
};

export default LandingCarousel;
